export enum FeesOverrideType {
  DOC_STAMP = 'DOC_STAMP',
  CBU = 'CBU',
  PF = 'PF',
  TP = 'TP',
  INSURANCE_FEE = 'INSURANCE_FEE',
  INSURANCE_SERVICE_FEE = 'INSURANCE_SERVICE_FEE',
  INSURANCE_PROCESSING_FEE = 'INSURANCE_PROCESSING_FEE',
  NOTARIAL_FEE = 'NOTARIAL_FEE',
  APPLICATION_FEE = 'APPLICATION_FEE',
  CREDIT_INVESTIGATION_FEE = 'CREDIT_INVESTIGATION_FEE',
  SERVICE_CHARGE = 'SERVICE_CHARGE',
  ID_FEE = 'ID_FEE',
  EXTRA_BANK_FEE = 'EXTRA_BANK_FEE',
  MEMBERSHIP_FEE = 'MEMBERSHIP_FEE',
  PENALTY = 'PENALTY',
  PENALTY_MATURITY = 'PENALTY_MATURITY',
  PAST_DUE_INTEREST = 'PAST_DUE_INTEREST',
  PAST_DUE_INTEREST_MATURITY = 'PAST_DUE_INTEREST_MATURITY'
}
