import {SimulatedFee} from '../shared/model/loan-simulation.model'
import {FeesOverrideType} from '../components/loan-applications/loan-application/steps/shared/simulation/fees-override/fees-override-type.model';

export const sortSimulatedFees = (simulatedFees: SimulatedFee[]): SimulatedFee[] => simulatedFees.sort(compare);

const compare = (simulatedFee1: SimulatedFee, simulatedFee2: SimulatedFee): number => {
  const simulatedFeesValues = Object.values(FeesOverrideType) as string[];
  const index1 = simulatedFeesValues.indexOf(simulatedFee1.feeSourceCode);
  const index2 = simulatedFeesValues.indexOf(simulatedFee2.feeSourceCode);

  if (index1 === -1 && index2 === -1) {
    return 0;
  }

  if (index1 === -1) {
    return 1;
  }

  if (index2 === -1) {
    return -1;
  }

  return index1 - index2;
};